<template>
  <div class="containers">
    <div id="doms">
      <div class="title">暂缓缴纳社保告知书</div>
      <div class="inputBox">
        <div class="itemTitle">{{query.company_name}}：</div>
        <div class="itemText">因本人与前用人单位社保档案移交原因，且本人目前处于试用期状态，工作期限不稳定，不愿意频繁进行社保档案移交，自愿申请暂缓缴纳社保。申请公司每月在工资中发放社保补贴包含在工资中。后续需要公司给我缴纳社保，本人提交缴纳社保书面申请，请公司于收到申请的次月为我缴纳社保。如果需要公司补交社保，本人提交补缴社保书面申请，并承诺退回已收到的社保补贴，请公司在收到我退回社保补贴的次月为我补缴。</div>
        <div class="itemTitle">声明与承诺：</div>
        <div class="itemText">1：本人完全清楚公司不为其缴纳社保是基于本人申请，与公司无关。本人保证工作期间及离职后均不会因社保问题无理投诉或仲裁公司，本人自愿承担申请放弃缴纳社保的法律责任。 </div>
        <div class="itemText">2：本人自愿放弃参保存在的风险，公司已全部告知，本人愿意承担因未缴纳社会保险产生的一切不利后果，也同意免除公司因未缴纳社保所产生的一切经济补偿等各项赔偿义务。</div>
        <div class="itemText">3：本人若需要参加社会保险时，将会提前一个月向公司提出书面申请，并提交相应的书面参保文件。</div>
        <div class="itemText">4：本人保证日后均不在任何时候、以任何理由，提出关于缴纳社保事宜的抗辩、控告、法律仲裁或诉讼主张。如本人违反本申请，自愿返还公司支付给本人的所有补贴。</div>
        <div class="itemText">5：本申请书将作为本人与公司《劳动合同》补充协议，取代《劳动合同》中相关社保的约定条款。</div>
        <div class="itemText">6：本人充分了解本协议的性质，纯属本人真实意愿，不存在被隐瞒、被欺诈或被胁迫的情况。即日起生效。</div>
      </div>
      <div class="inputs">
        <div class="itemTitle">身 份 证 号 码：</div>
        <van-field class="inputsItem" v-model="query.ID_number" />
      </div>
      <div class="inputs">
        <div class="itemTitle">住 址：</div>
        <van-field class="inputsItem" v-model="query.address" />
      </div>
      <div class="inputs">
        <div class="itemTitle">电 话：</div>
        <van-field class="inputsItem" v-model="query.phone" />
      </div>
      <div class="inputs">
        <div class="itemTitle">微 信：</div>
        <van-field class="inputsItem" v-model="query.weixin" />
      </div>
      <div class="inputs selfStyle">
        <div class="itemTitle">日 期：</div>
        <van-field class="inputs1 inputBorder36" v-model="query.select_year" />
        年
        <van-field class="inputs1 inputBorder36" v-model="query.select_month" />
        月
        <van-field class="inputs1 inputBorder36" v-model="query.select_day" />
        日
      </div>
      <div class="sign">
        <span class="signLabel">入职人签字:</span>
        <vue-esign v-if="!query.sign_file" id="sign"  class="card" ref="esign" :height="350" :isCrop="false" :lineWidth="10" :bgColor.sync="bgColor" :lineColor="'#000'" />
        <img v-else class="card" :src="query.sign_file" alt="">
      </div>
      <div class="reset" @click="resetSign">重新签名</div>
      <div class="submitBtn" @click="submitInfo">保存</div>
    </div>
    <!--    <img src="../../../static/noPaper/hetong.png" alt="" class="zhang">-->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import axios from "axios";
import {SaveInsurance, loadEntry, uploadFile, LoadInsurance} from "@/api/paper";
export default {
  data() {
    return {
      query: {
        sign_info: ''
      },
      isShow: true,
      bgColor: '',
      fiveText: '本人承诺所提供的工作履历是完整的和真实的，如有任何不真实的信息，本人愿意接受被公司立即辞退并解除劳动合同，个人不附加任何的诉求。'
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      LoadInsurance({openId: this.$store.state.info.openId}).then(res => {
        if(res.row) {
          this.query = res.row
        }
      })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async submitInfo() {
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          // ajax 请求
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      SaveInsurance(this.query).then(res => {
        console.log(res);
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$router.go(-1)
        }
      })
    },
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.containers {
}
#doms {
  box-sizing: border-box;
  padding: 14px 10px;
}
.inputBox {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  color: #666666;
  line-height: 20px;
  .itemTitle {
    font-weight: bold;
  }
}
.tips {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 11px;
  color: #777777;
  line-height: 18px;
  margin: 18px 0;
}
.sign {
  margin-top: 50px;
  .signLabel {
    flex-shrink: 0;
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
  }
  .card {
    width: 100%;
    height: 70px;
    background: #F7F8FA;
    border: 1px solid #DDDDDD;
  }
}
.reset {
  position: absolute;
  right: 10px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #02DBCA;
  color: #02DBCA;
  border-radius: 10px;
  margin-top: 15px;
}
.submitBtn {
  background: #02DBCA;
  border-radius: 20px;
  color: #FFFFFF;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.itemText {
  text-indent: 2em;
}
.zhang {
  position: fixed;
  width: 180px;
  height: 180px;
  right: 10px;
  bottom: 150px;
}
.inputs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .itemTitle {
    flex-shrink: 0;
    width: 87px;
    text-align: right;
  }
  .inputsItem {
    font-size: 12px;
    border: 1px solid #DDDDDD;
    padding: 0 5px !important;
  }
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 60px 0;
  color: #000000;
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
